.product__main {
    width: 100%;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 100px;
    --tp-balck: #03041c;
    --tp-pink: #f50963;
    --tp-widlth: #e2e2e2;
    --tp-color2: #525258;

    .product_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 70px;

        @media (max-width:576px) {
            margin-right: 0;
        }

        .product__main_img {
            width: 100%;
            margin-bottom: 14px;

            img {
                width: 100%;
                max-height: 575px;
                color: transparent;
                object-fit: cover;
            }
        }

        .product__item {
            width: 100%;

            .product_img {
                display: flex;
                align-items: center;

                .active_link {
                    border: 1px solid var(--tp-pink);
                }

                button {
                    border: none;
                    outline: none;
                    width: 110px;
                    height: 110px;
                    margin-right: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        color: transparent;
                    }
                }
            }
        }
    }

    .product__text {
        .product__detail_text {
            margin-bottom: 10px;

            span {
                width: 100%;
                border: 1px solid var(--tp-pink);
                padding: 5px 10px;
                color: #f50963;

            }
        }

        .product__price {
            display: flex;
            align-items: center;

            del {
                color: #525258;
                font-size: 13px;
            }

            h3 {
                font-size: 17px;
                font-weight: 600;
                color: #03041c;
                margin: 0 5px;
            }

            span {
                background-color: #f50963;
                color: #fff;
                font-size: 13px;
                padding: 1px 10px;
                font-weight: 500;
            }

        }

        .table__quantity {
            display: flex;
            align-items: center;
            border: 1px solid #f1f1f1;
            width: 120px;
            height: 35px;

            span {
                cursor: pointer;
                width: 100%;
                font-size: 17px;
                font-weight: 600;
                text-align: center;

                &:hover {
                    background-color: #f1f1f1;
                    color: #f50963;
                }
            }

            .table__item {
                border-left: 1px solid #f1f1f1;
                border-right: 1px solid #f1f1f1;
                width: 100%;
                text-align: center;

                input {
                    width: 50%;
                    text-align: center;
                    border: 0;
                    outline: 0;
                }
            }
        }

        .product__chill_item {
            align-items: center;
            width: 100%;
            display: flex;
            align-items: center;

            button {
                border: none;
                outline: none;
                color: #525258;
                background-color: #fff;
                width: 35px;
                height: 35px;
                margin-right: 5px;
                margin-bottom: 6px;
                box-shadow: 0 1px 3px rgba(3, 4, 28, 0.12);

                i {
                    font-size: 14px;
                }

                &:hover {
                    color: #fff;
                    background-color: #f50963;
                }
            }

            .product__cart {
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 10px;
                bottom: 0;
                margin-right: 10px;
                background-color: #03041c;
                text-align: center;
                cursor: pointer;
                width: 100%;

                i {
                    margin-right: 5px;
                }

                h3 {
                    color: inherit;
                    font-size: 15px;
                    font-weight: 600;
                    margin: 0;
                }

                &:hover {
                    background-color: #f50963;
                }
            }
        }

        .product__footer {
            width: 100%;
            padding-top: 10px;

            .item {
                display: flex;
                align-items: center;

                h3 {
                    font-size: 17px;
                    color: #03041c;
                    font-weight: 600;
                    margin: 0 5px;
                }

                span {
                    font-size: 15px;
                    font-weight: 500;
                    color: #525258;
                    display: flex;
                    align-items: center;

                    div {
                        margin-right: 5px;
                    }
                }
            }
        }

    }
}