.container {
    max-width: 1200px;
  }
  
  h4 {
    font-weight: bold;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .list-group-item {
    border: none;
    padding: 0.75rem 1.25rem;
  }
  .item:hover{
    color:  #f50963;
    cursor: pointer;
  }
  .list-group-item:hover {
    background-color: #f8f9fa;
    color:  #f50963;
    cursor: pointer;
  }
 //pink
 /* Lớp tùy chỉnh màu hồng */
.pink {
  background-color: #f50963 !important; /* Ghi đè màu nền hồng */
  border-color: #f50963 !important   /* Màu viền hồng */
}

/* Hiệu ứng nhấp nháy cho nút */
.btn-blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

//hostinger
.hostinger_deals{
  button {
  transition: 0.3s ease-in-out;
  border: 1px solid #f23b6c !important;
  color: #f23b6c !important;
  
}

button:hover {
  background-color: #f23b6c !important;
  color: #fff !important;
}
}