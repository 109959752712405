.product_cart{
    padding: 100px 0;
    .product_cart_slider{
        .swiper-wrapper{
           text-align: center;
        }
        .swiper-button-prev,.swiper-button-next{
            font-size: 1rem;
            color: #03041c;
            &:hover{
                color:#f50963;
            }
        }
        .swiper-scrollbar{
            font-size: 2px;
            height: 2px;
            bottom: 0;
            background-color: #f50963;
            .swiper-scrollbar-drag{
                background-color:#e2e2e2;
            }
        }
        .product__cart_slider{
            margin-bottom: 30px;
            .product__mgs{
                cursor: pointer;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    color: transparent;
                }
            }
            .product__content{
                h3{
                    a{
                        text-decoration: none;
                        font-size: 16px;
                        color: #525258;
                    }
                }
            }
        }
    }
}