.hero-section {
    background-color: #007bff;
    color: white;
    padding: 80px 0;
  }
  
  .deal-card {
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .deal-card h3 {
    font-size: 1.5rem;
  }
  
  .footer {
    background-color: #222;
    color: white;
    padding: 20px 0;
    text-align: center;
  }
  